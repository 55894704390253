import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'bookmark';
const namespace = 'ui-pdp-icon ui-pdp-icon--bookmark';

const IconBookmark = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
  >
    <g fillRule="evenodd">
      <use href={`#${ICON_ID}`} />
    </g>
  </svg>
);

IconBookmark.propTypes = {
  className: string,
};

IconBookmark.defaultProps = {
  className: null,
};

IconBookmark.ICON_ID = ICON_ID;

export default React.memo(IconBookmark);
export { IconBookmark };
